import React from 'react'
import {Card,Popover } from 'react-bootstrap'
import { Link} from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import info from '../img/info.png'

const Dashcard = ({ title, path, tooltipMessage}) => {
   const popoverTop = (
    <Popover id="popover-trigger-click" title="Popover top" style={popoverStyle}>
      {tooltipMessage}
    </Popover>
  );
  return (
    <Card className="dashcard">
      <Card.Body>
      <OverlayTrigger
      trigger="click"
      rootClose
      overlay={popoverTop}
      placement='top'
    >
        <img src={info} style={infoIcon}/>
        </OverlayTrigger>

        <center>
            <Link to={path} className="dashcardlink">
                <Card.Title style={cardtitle}>{title}</Card.Title>
            </Link>
        </center>
      </Card.Body>
    </Card>
   
    
  
  )
}
const popoverStyle={
  padding:15,
  fontSize:18
}
const infoIcon={
  position:'absolute',
  top:15,
  right:15,
  width:20,
  height:20,
  tintColor:'green'
}

const cardtitle = {
  marginTop:25,
  textDecoration:'none',
  color:'black'
}
const cardnum = {
  fontSize: '210%',
  fontWeight: 700,
  textDecoration:'none'
}

export default Dashcard