import React, { useState, useEffect } from "react";
import {
    Card,
    Button,
    Col,
    Row,
    Container,
    Form,
    Table,
    Modal
} from "react-bootstrap";
import axios from "axios";
import LoaderSpinner from "../common/LoaderSpinner";
import Header from "./Header";
import { Url } from '../constant/Url'
function Agentlist() {
    let u = JSON.parse(localStorage.getItem('user'))

    const [userinfo, setUserinfo] = useState(u)
    const [userlist, setUserlist] = useState([]);
    const [userlist2, setUserlist2] = useState([]);
    const [showloader, setShowloader] = useState(false);
    const [show, setShow] = useState(false);
    const [curragent, setCurragent] = useState({})
    const [leadslist, setLeadslist] = useState([]);

    const getmyleads = (agentid) => {
        var data = JSON.stringify({
            "agent_id": agentid
        });

        var config = {
            method: 'post',
            url: Url.baseurl + 'agentlead/list',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setLeadslist(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const handleClose = () => setShow(false);
    const handleShow = (item) => {
        setShow(true);
        setCurragent(item);
        getmyleads(item.userid);
    }

    const getlist = () => {
        setShowloader(true)
        var data = JSON.stringify({
            role: "admin",
        });

        var config = {
            method: "post",
            url: Url.baseurl + "users/userlist",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setShowloader(false)
                console.log(JSON.stringify(response.data));
                setUserlist(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    //getlist2
    const getlist2 = () => {
        setShowloader(true)
        var data = JSON.stringify({
            role: "agent",
        });

        var config = {
            method: "post",
            url: Url.baseurl + "users/userlist",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setShowloader(false)
                console.log(JSON.stringify(response.data));
                setUserlist2(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };


    useEffect(() => {
        getlist2();
    }, []);

    useEffect(() => {
        //getlist2();
    }, [userlist]);
    return (
        <div className="mybody">
            <center>
                <Card className="form-center shadow" style={{ width: "60%" }}>
                    <Header />
                    <Card.Body>
                        <Row>
                            <Col>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>User Name</th>
                                            <th>Password</th>
                                            <th>Role</th>
                                            <th>Company</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userlist.length > 0
                                            ? userlist.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.username}</td>
                                                        <td>{item.password}</td>
                                                        <td>{item.role}</td>
                                                        <td>{item.company}</td>
                                                        <td> <Button className="btnstyle" onClick={handleShow} >View leads</Button>{' '}</td>
                                                    </tr>
                                                );
                                            })
                                            : null}

                                        {userlist2.length > 0
                                            ? userlist2.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.username}</td>
                                                        <td>{item.password}</td>
                                                        <td>{item.role}</td>
                                                        <td>{item.company}</td>
                                                        <td> <Button className="btnstyle" onClick={handleShow.bind(this, item)} variant="success">View leads</Button>{' '}</td>
                                                    </tr>
                                                );
                                            })
                                            : null}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {showloader && <LoaderSpinner type="Circles" color="#00aced" />}
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton variant="danger" >
                        <Modal.Title>{curragent?.username}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="listholder">

                            {leadslist?.length > 0 ?
                                leadslist.map((item, index) => {
                                    let p = item.phone.toString();
                                    let f = p.substring(0, 2);
                                    let e = p.slice(-3);
                                    let ph = f + "XXXXX" + e;
                                    let mail = item.email.toString();
                                    let first = mail.substring(0, 2);
                                    let last = mail.slice(-2);
                                    let mail2 = first + "XXXXX" + last;
                                    return <Card style={{ width: '99%', marginBottom: 10 }}>

                                        <Card.Body>
                                            <Card.Title>{item.first_name + " " + item.last_name}</Card.Title>
                                            <Card.Text>
                                                <p> <strong>Email </strong> &nbsp; {mail2}</p>
                                                <p> <strong>Phone </strong> &nbsp; {ph}</p>
                                                <p> <strong>Gender </strong> &nbsp; {item.gender}</p>
                                                <p> <strong>City </strong> &nbsp; {item.city}</p>
                                                <p> <strong>Pincode </strong> &nbsp; {item.pincode}</p>
                                            </Card.Text>

                                        </Card.Body>
                                    </Card>
                                })
                                : null}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
            </center>
        </div>
    );
}

export default Agentlist;
