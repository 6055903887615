import React from 'react'

const Termdatatel = ({ frmdata }) => {
    return (
        <>
            {frmdata.terms} &nbsp; <a href={frmdata.termurl} target="_blank">{frmdata.termtxt}</a> &nbsp;మరియు &nbsp;<a href={frmdata.privacyurl} target="_blank">{frmdata.privacytxt}</a>&nbsp; {frmdata.moreterms}
        </>
    )
}

export default Termdatatel