import PropTypes from "prop-types";
import { Redirect, Route, Switch, useNavigate } from "react-router-dom";
//import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import {
    Card,
    Button,
    Col,
    Row,
    Container,
    Form,
    Alert,
    Dropdown
} from "react-bootstrap";
import LoaderSpinner from "../common/LoaderSpinner";
import React, { useState, useContext } from "react";
import axios from "axios";
import Header from "./Header";
import { v4 as uuidv4 } from "uuid";
import { Url } from '../constant/Url'

function Verifycode() {
    let u = JSON.parse(localStorage.getItem('user'))
    const [userinfo, setUserinfo] = useState(u)
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [retypepassword, setRetypePassword] = useState("");
    const [role, setRole] = useState("select role");
    const [company, setCompany] = useState("");
    const [formerror, setFormerror] = useState("");
    const [passworderror, setPassworderror] = useState("");
    const [fullname, setFullname] = useState("");
    const [phone, setPhone] = useState("");
    const [code, setCode] = useState("");
    const [codeerror, setCodeerror] = useState("");
    const [formsucess, setFormSuccess] = useState("");
    const [formfailure, setFormFailure] = useState("");
    const [failerror, setFailerror] = useState("");
    const [showloader, setShowloader] = useState(false);
    let navigate = useNavigate();
    //let history = useHistory()
    const validate = () => {
        if (
            username.length == 0 ||
            password.length == 0 ||
            retypepassword.length == 0 ||
            role.length == 0 ||
            company.length == 0 ||
            company.length == 0 ||
            phone.length == 0 ||
            fullname.length == 0
        ) {
            setFormerror("Please enter a valid user information");
            return false;
        } else {
            setFormerror("");
            return true;
        }
    };
    const checkpassword = () => {
        if (password == retypepassword) {
            setPassworderror("");
            return true;
        } else {
            setPassworderror("Password does not match");
            return false;
        }
    };
    const Verifycode = () => {
        if (code.length == 0) {
            setCodeerror("Please enter a valid code");
        }
        else {
            setCodeerror("")
            //api call
            setShowloader(true);
            var data = JSON.stringify({
                "code": code.toString(),
                "agent_id": u.userid.toString()
            });

            var config = {
                method: "post",
                url: Url.baseurl + "users/redeem",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    setShowloader(false);
                    if (response.data.status == 201) {
                        setFormSuccess(true)
                        setFormFailure(false)
                        setCode('')
                    } else {
                        setFormSuccess(false)
                        setFormFailure(true)
                        setFailerror(response.data.message)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            //end api call
        }
    }

    const Createuser = () => {
        if (validate() && checkpassword()) {
            //call API
            setShowloader(true);
            var data = JSON.stringify({
                "userid": uuidv4(),
                "username": username,
                "password": password,
                "company": "digicides",
                "blocked": 0,
                "deleted": 0,
                "role": role,
                "phone": phone,
                "fullname": fullname
            });

            var config = {
                method: "post",
                url: Url.baseurl + "users/usercreate",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    setShowloader(false);
                    if (response.data.status == 201) {
                        navigate("/userlist");
                        // history.push('/userlist');
                    } else {
                        setFormerror("USER CREATION FAILED");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
        }
    };
    return (
        <div className="mybody">
            <center>
                <Card className="form-center shadow" style={{ width: "60%" }}>
                    <Header />
                    <Card.Body>
                        <Form>
                            <h3>User code verification</h3>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Verification code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                                {codeerror.length > 0 && <Alert style={{ marginTop: 10 }} variant={"danger"}>
                                    {codeerror}
                                </Alert>}
                            </Form.Group>






                            <Button
                                className="btn1"
                                type="button"
                                disabled={showloader}
                                onClick={Verifycode}
                            >
                                Verify
                            </Button>
                            {formerror.length > 0 ? (
                                <Alert className="my-4" variant="danger">
                                    {formerror}
                                </Alert>
                            ) : null}
                            {passworderror.length > 0 ? (
                                <Alert className="my-4" variant="danger">
                                    {passworderror}
                                </Alert>
                            ) : null}
                            {formsucess == true && <Alert style={{ marginTop: 10 }} variant={"success"}>
                                User Verified Sucessfully
                            </Alert>}
                            {formfailure == true && <Alert style={{ marginTop: 10 }} variant={"danger"}>
                                {failerror}
                            </Alert>}
                        </Form>
                    </Card.Body>
                </Card>
                {showloader && <LoaderSpinner type="Circles" color="#00aced" />}
            </center>
        </div>
    );
}

export default Verifycode;
