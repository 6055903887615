import PropTypes from "prop-types";
import { Redirect, Route, Switch, useNavigate } from "react-router-dom";
//import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import {
    Card,
    Button,
    Col,
    Row,
    Container,
    Form,
    Alert,
    Dropdown
} from "react-bootstrap";
import { Link } from "react-router-dom";
import LoaderSpinner from "../common/LoaderSpinner";
import React, { useState, useContext } from "react";
import axios from "axios";
import Header from "./Header";
import { v4 as uuidv4 } from "uuid";
import { Url } from '../constant/Url'

function Createschedule() {

    const [formerror, setFormerror] = useState("");
    const [passworderror, setPassworderror] = useState("");
    const [fullname, setFullname] = useState("");

    const [showloader, setShowloader] = useState(false);
    let navigate = useNavigate();
    //let history = useHistory()
    const validate = () => {
        if (
            fullname.length == 0
        ) {
            setFormerror("Please enter a valid user information");
            return false;
        } else {
            setFormerror("");
            return true;
        }
    };


    const addschedule = () => {
        //call API
        if (validate()) {
            setShowloader(true);
            let data = JSON.stringify({
                "name": fullname,
                "company_id": "virtako",
                "user_id": "u11"
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: Url.baseurl + 'schedule',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    setShowloader(false);
                    if (response.data.status == 201) {
                        navigate("/schedulelist");
                        // history.push('/userlist');
                    } else {
                        setFormerror("SCHEDULE CREATION FAILED");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    return (
        <div className="mybody">
            <center>
                <Card className="form-center shadow" style={{ width: "60%" }}>
                    <Header />
                    <Row style={buttonrow}>
                        <Col>
                            <Link to="/schedulelist">
                                <Button style={{marginLeft:20,marginTop:10}}>View Schedule List</Button>
                            </Link>        
                        </Col>
                    </Row>
                    <Card.Body>
                        <h2>Create Schedule</h2>
                        <Form>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                    type="text"
                                    placeholder="Enter schedule name ..."
                                    value={fullname}
                                    onChange={(e) => setFullname(e.target.value)}
                                />
                            </Form.Group>


                            <Button
                                className="btn1"
                                type="button"
                                disabled={showloader}
                                onClick={addschedule}
                            >
                                Create Schedule
                            </Button>
                            {formerror.length > 0 ? (
                                <Alert className="my-4" variant="danger">
                                    {formerror}
                                </Alert>
                            ) : null}
                            {passworderror.length > 0 ? (
                                <Alert className="my-4" variant="danger">
                                    {passworderror}
                                </Alert>
                            ) : null}
                        </Form>
                    </Card.Body>
                </Card>
                {showloader && <LoaderSpinner type="Circles" color="#00aced" />}
            </center>
        </div>
    );
}
const buttonrow={
    flexDirection:'row',
    marginBottom:20
  }

export default Createschedule;
