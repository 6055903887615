import React, { useState, useEffect } from "react";
import {
    Card,
    Button,
    Col,
    Row,
    Container,
    Form,
    Table,
    Modal,
    Alert,
    FloatingLabel,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import LoaderSpinner from "../common/LoaderSpinner";
import Header from "./Header";
import { Url } from '../constant/Url'

function Campaignlist() {
    const [cmplist, setcmplist] = useState([]);
    const [currentcampaign, setCurrentcampaign] = useState({})
    const [showloader, setShowloader] = useState(false);
    const [show, setShow] = useState(false);
    const [formerror, setFormerror] = useState("");
    const [passworderror, setPassworderror] = useState("");
    const [cname, setcname] = useState("");
    const [cdate, setcdate] = useState("");
    const [cid, setCid] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(!show);


    const getlist = () => {
        setShowloader(true)
        let data = JSON.stringify({
            "page": 1,
            "campaign_date": "-"
          });
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: Url.baseurl2 + 'campaign/list',
            headers: {
                'Content-Type': 'application/json'
            },
            data : data,
            
        };

        axios.request(config)
            .then((response) => {
                setShowloader(false)
                console.log("campaign list data",JSON.stringify(response.data));
                setcmplist(response.data.data)
            })
            .catch((error) => {
                setShowloader(false)
                console.log(error);
            });

    };
    const deletecampaign = (campaign) => {
        let a = window.confirm("Are you sure you want to delete?")
        if (a) {
            setShowloader(true)
            let data = JSON.stringify({
                "id": campaign.id
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: Url.baseurl2 + 'campaign/delete',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    setShowloader(false)
                    console.log(JSON.stringify(response.data));
                    getlist()
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }

    const editcampaign = (campaign) => {
        setCurrentcampaign(campaign)
        handleShow()
        setcname(cname => campaign?.cname)
        setCid(cid => campaign?.id)
        setcdate(cdate => campaign?.cdate )
    }
    const updatecampaign = () => {
        
        //call API
        if (validate()) {
            var cd=cdate.split("-")
            var cdate2=cd[2]+"-"+cd[1]+'-'+cd[0]
            setShowloader(true);
            let data = JSON.stringify({
                "id":cid,
                "campaign_name":cname,
                "campaign_date": cdate2
            });

            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: Url.baseurl2 + 'campaign',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    setShowloader(false);
                    if (response.data.status == 201) {
                        handleShow()
                        getlist()
                    } else {
                        setFormerror("Error ! Campaign could not be updated");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
     const validate = () => {
        if (cname.length == 0) {
            setFormerror("Please enter a name");
            return false;
        } 
        else if(cdate==''){
            setFormerror("Please enter a valid date");
            return false;
        }
        else {
            setFormerror("");
            return true;
        }
    };

    useEffect(() => {
        getlist();
    }, []);

    
    return (
        <div className="mybody">
            <center>
                <Card className="form-center shadow" style={{ width: "60%" }}>
                    <Header />
                    <Row style={buttonrow}>
                        <Col style={{float:'right'}}>
                            <Link to="/createcampaign">
                                <Button className="btn1">+ New Campaign</Button>
                            </Link>        
                        </Col>
                    </Row>
                    <Card.Body>
                        <Row>
                            <Col>
                                <h2>Campaigns</h2>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Date</th>
                                            <th>Actions</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cmplist?.length > 0
                                            ? cmplist.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item?.cname}</td>
                                                        <td>{item?.cday+"/"+item?.cmonth+"/"+item?.cyear}</td>
                                                        <td>
                                                            <Button className="btn1" onClick={editcampaign.bind(this, item)}>Update</Button>{' '}
                                                            <Button className="btn11" onClick={deletecampaign.bind(this, item)}>Remove</Button>{' '}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            : null}


                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {showloader && <LoaderSpinner type="Circles" color="#00aced" />}
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Campaign</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <label>Name</label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter campaign name"
                                    value={cname}
                                    onChange={(e) => setcname(e.target.value)}
                                />
                                
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <label>Date</label>
                                <Form.Control
                                        type="date"
                                        placeholder="Enter campaign date"
                                        value={cdate}
                                        onChange={(e) => setcdate(e.target.value)}
                                    />
                            </Form.Group>



                            {formerror.length > 0 ? (
                                <Alert className="my-4" variant="danger">
                                    {formerror}
                                </Alert>
                            ) : null}
                            {passworderror.length > 0 ? (
                                <Alert className="my-4" variant="danger">
                                    {passworderror}
                                </Alert>
                            ) : null}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            type="button"
                            disabled={showloader}
                            onClick={updatecampaign}
                        >Update</Button>
                    </Modal.Footer>
                </Modal>

                            
            </center>
        </div>
    );
}
const buttonrow={
    flexDirection:'row',
    marginBottom:20
  }
export default Campaignlist;
