import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'


const Footer = () => {
    return (
        <Row>
            <Col md={12} lg={12} sm={12} xs={12}>
                <div className="foot">
                    <p className="footstyle">Copyright&copy; 2021</p>
                </div>
            </Col>
        </Row>

    )
}

export default Footer