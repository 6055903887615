import React, { useState, useEffect } from 'react'
import { Card, Button, Col, Row, Container, Form, Alert, FloatingLabel } from "react-bootstrap"
import logo from '../img/kv1.jpg'
import Url from '../common/Url'
import axios from 'axios'
import LoaderSpinner from "../common/LoaderSpinner";
import { v4 as uuidv4 } from "uuid";
import Termdata from './Termdata'
import Termdatatel from './Termdatatel'


const Queryform = ({ frmdata }) => {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [nameerror, setNameerror] = useState('')
    const [phoneerror, setPhoneerror] = useState('')
    const [showloader, setShowloader] = useState(false);
    const [showthanks, setShowthanks] = useState(false);
    const [bd, setBD] = useState(false);

    const sendData = () => {
        if (name == '' || phone == '') {
            if (name.length == 0) { setNameerror(frmdata.nameerror) }
            if (phone.length == 0) { setPhoneerror(frmdata.phoneerror1) }
        }
        else if (phone.length != 10 || isNaN(phone)) { setPhoneerror(frmdata.phoneerror2) }

        else {
            setNameerror('')
            setPhoneerror('')
            storeuser()
        }
    }
    const storeuser = () => {
        setShowloader(true);
        setBD(true)
        var data = JSON.stringify({
            "id": uuidv4(),
            "name": name,
            "phone": phone,
            "website": frmdata.website,
            "company": frmdata.company,
            "product": frmdata.product,
            "campaign_id": frmdata.companyid,
            "state": frmdata.state,
            "district": frmdata.district,
            "village": frmdata.village,
            "rice_acarages": "not applicable",
            "source": frmdata.source,
            "language": frmdata.language,

        });

        var config = {
            method: 'post',
            url: 'https://ykkswt6jb1.execute-api.ap-south-1.amazonaws.com/Prod/storeuser',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setShowloader(false);
                setShowthanks(true);
                setName('')
                setPhone('')
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const ttext = frmdata.language == 'english' ? <Termdata frmdata={frmdata} /> : <Termdatatel frmdata={frmdata} />;
    useEffect(() => {

    }, [])
    return (
        <Card style={{ width: '99%' }} className="qform trs">

            <Card.Body>
                <img src={logo} alt="logo" className="flogo" />
                {showthanks == false ? <>
                    <Card.Text className="gtxt">
                        {frmdata.frminfo}
                    </Card.Text>
                    <>
                        <Form.Group className="mb-3 text-left" controlId="formBasicEmail" >
                            <Form.Label className="text-left" style={{ textAlign: 'left' }}>{frmdata.namelabel}</Form.Label>
                            <Form.Control value={name} onChange={(e) => { setName(e.target.value) }} type="text" placeholder={frmdata.nameholder} />
                            {nameerror.length > 0 ? <Alert variant="danger">
                                {nameerror}
                            </Alert> : null}
                        </Form.Group>

                        <Form.Group className="mb-3 text-left" controlId="formBasicPassword">
                            <Form.Label className="text-left">{frmdata.phonelabel}</Form.Label>
                            <Form.Control value={phone} onChange={(e) => { setPhone(e.target.value) }} type="text" placeholder={frmdata.phoneholder} />
                            {phoneerror.length > 0 ? <Alert variant="danger">
                                {phoneerror}
                            </Alert> : null}
                        </Form.Group>

                    </>
                    <br />
                    <div align="left">
                        <Button onClick={sendData} variant="warning" className="testbutton" disabled={bd}>{frmdata.submitbtn}&nbsp;&#10148;</Button>
                    </div>
                    <br />
                    <Card.Text className="gtxt">
                        {ttext}
                    </Card.Text>
                </> : null}
                {showthanks == true ? <Card.Text className="gtxt">
                    <Alert variant="success">   {frmdata.thankyou} </Alert>
                </Card.Text> : null}
                {showloader && <LoaderSpinner type="Circles" color="#00aced" />}
            </Card.Body>
        </Card>
    )
}

export default Queryform