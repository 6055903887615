import React from 'react'
import { Card, Button, Col, Row, Container, Form, Alert } from "react-bootstrap"
import cornbg from '../img/corn-bg2.jpg'
import Queryform from './Queryform'
import Head from './Head'
import Footer from './Footer'
import Notfound from './Notfound'
import { useParams, useLocation } from "react-router-dom";
import language from "../language.json";
const Home = () => {
    const { langs } = useParams();
    console.log('lang', langs)
    let lang = 'english'
    let nf = false
    if (langs == 'english' || langs == 'telugu') {
        lang = langs
    }
    else if (langs !== undefined) {
        nf = true
    }

    const { namelabel, phonelabel, nameholder, phoneholder, submitbtn, terms, nameerror, phoneerror1, phoneerror2, frminfo, company, product, website, companyid, state, district, village, source, thankyou, moreterms, termurl, privacyurl, termtxt, privacytxt } = language[lang]
    const frmdata = {
        namelabel: namelabel,
        phonelabel: phonelabel,
        nameholder: nameholder,
        phoneholder: phoneholder,
        submitbtn: submitbtn,
        terms: terms,
        nameerror: nameerror,
        phoneerror1: phoneerror1,
        phoneerror2: phoneerror2,
        frminfo: frminfo,
        company: company,
        product: product,
        website: website,
        companyid: companyid,
        district: district,
        village: village,
        state: state,
        language: lang,
        source: source,
        thankyou: thankyou,
        moreterms: moreterms,
        termurl: termurl,
        privacyurl: privacyurl,
        termtxt: termtxt,
        privacytxt: privacytxt

    }
    const bg = {
        backgroundColor: '#008942',
        backgroundImage: `url(${cornbg})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',

    }
    return (
        <>

            <Container fluid>
                <Head />

                <Row>
                    {nf == false ? <div className="bdy" align="center">

                        <Col md={4} xs={12}>


                            <Card className="content" style={bg}>

                                <Card.Body>
                                    <Queryform frmdata={frmdata} />
                                </Card.Body>

                            </Card>
                        </Col>
                    </div> : <Notfound />}
                </Row>
                <Footer />
            </Container>



        </>
    )
}

export default Home