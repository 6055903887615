
import { useNavigate } from "react-router-dom";
import {
    Card,
    Button,
    Col,
    Row,
    Form,
    Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import LoaderSpinner from "../common/LoaderSpinner";
import React, { useState } from "react";
import axios from "axios";
import Header from "./Header";
import { Url } from '../constant/Url'

function Createcampaign() {

    const [formerror, setFormerror] = useState("");
    const [passworderror, setPassworderror] = useState("");
    const [cname, setCname] = useState("");
    const [cdate, setCdate] = useState("");

    const [showloader, setShowloader] = useState(false);

    let navigate = useNavigate();

    const validate = () => {
        if (cname.length == 0) {
            setFormerror("Please enter a valid name for campaign");
            return false;
        }
        else if (cdate == '') {
            setFormerror("Please select a date for the campaign");
            return false;
        }
        else {
            setFormerror("");
            return true;
        }
    };


    const addcampaign = () => {

        //call API
        if (validate()) {
            var cd = cdate.split("-")
            var cdate2 = cd[2] + "-" + cd[1] + '-' + cd[0]
            setShowloader(true);
            let data = JSON.stringify({
                "campaign_name": cname,
                "campaign_date": cdate2
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: Url.baseurl2 + 'campaign',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    setShowloader(false);
                    if (response.data.status == 201) {
                        navigate("/campaignlist");
                    } else {
                        setFormerror("Error ! Campaign could not be created");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    const gotolist = () => {
        navigate("/campaignlist");
    }
    const getmindate = () => {
        var dtToday = new Date();

        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate() + 1;
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();

        var minDate = year + '-' + month + '-' + day;
        return minDate;
    }
    return (
        <div className="mybody">
            <center>
                <Card className="form-center shadow" style={{ width: "60%" }}>
                    <Header />
                    {/* <Row style={buttonrow}>
                        <Col>
                            <Link to="/campaignlist">
                                <Button style={{marginLeft:20,marginTop:10}}>View Campaign List</Button>
                            </Link>        
                        </Col>
                    </Row> */}
                    <Card.Body>
                        <h2>Create Campaign</h2>
                        <Form>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                    type="text"
                                    placeholder="Enter CAMPAIGN name ..."
                                    value={cname}
                                    onChange={(e) => setCname(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control
                                    type="date"
                                    placeholder="Enter CAMPAIGN date"
                                    value={cdate}
                                    onChange={(e) => setCdate(e.target.value)}
                                    min={getmindate()}
                                />
                            </Form.Group>


                            <Button
                                className="btn1"
                                type="button"
                                disabled={showloader}
                                onClick={addcampaign}>
                                Create Campaign
                            </Button>

                            <Button onClick={gotolist} className="btn11">
                                View Campaign List
                            </Button>

                            {formerror.length > 0 ? (
                                <Alert className="my-4" variant="danger">
                                    {formerror}
                                </Alert>
                            ) : null}
                            {passworderror.length > 0 ? (
                                <Alert className="my-4" variant="danger">
                                    {passworderror}
                                </Alert>
                            ) : null}
                        </Form>
                    </Card.Body>
                </Card>
                {showloader && <LoaderSpinner type="Circles" color="#00aced" />}
            </center>
        </div>
    );
}
const buttonrow = {
    flexDirection: 'row',
    marginBottom: 20
}

export default Createcampaign;
