import React, { useState, useEffect } from "react";
import {
    Card,
    Button,
    Col,
    Row,
    Container,
    Form,
    Table,
    Modal,
    Alert,
    FloatingLabel,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import LoaderSpinner from "../common/LoaderSpinner";
import Header from "./Header";
import { Url } from '../constant/Url'
function Schedulelist() {
    const [schlist, setSchlist] = useState([]);
    const [rules, setRules] = useState([]);
    const [sch, setSch] = useState({})
    const [showloader, setShowloader] = useState(false);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [formerror, setFormerror] = useState("");
    const [formerror2, setFormerror2] = useState("");
    const [passworderror, setPassworderror] = useState("");
    const [fullname, setFullname] = useState("");
    const [cid, setCid] = useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);
    const [template, setTemplate] = useState("1007029655318016721");
    const [days, setDays] = useState("1")
    const [msg, setMsg] = useState("प्रिय किसान मित्र। वर्टाको के इस प्रदर्शन में रुचि दिखाने के लिए धन्यवाद। और अधिक जानकारी कि लिए कृपया लिंक पर क्लिक करें https://insectmanagement.in/virtakoandvoliamflexi/ और अपना विवरण दर्ज करें। - Syngenta")
    const [channel, setChannel] = useState("sms")
    const [actiontime, setActiontime] = useState("00:15")
    const [activity, setActivity] = useState([])


    const getlist = () => {
        setShowloader(true)
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: Url.baseurl + 'schedule',
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                setShowloader(false)
                console.log(JSON.stringify(response.data));
                setSchlist(response.data.data)
            })
            .catch((error) => {
                console.log(error);
            });

    };
    const delschedule = (id) => {
        let a = window.confirm("Are you sure you want to delete?")
        if (a) {
            setShowloader(true)
            let data = JSON.stringify({
                "id": id
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: Url.baseurl + 'schedule/delete',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    setShowloader(false)
                    console.log(JSON.stringify(response.data));
                    getlist()
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }

    const delrule = (id) => {
        let a = window.confirm("Are you sure you want to delete?")
        if (a) {
            setShowloader(true)
            let data = JSON.stringify({
                "id": id
            });

            let config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: Url.baseurl + 'schedule/rule/' + id,
                headers: {},

            };

            axios.request(config)
                .then((response) => {
                    setShowloader(false)
                    console.log(JSON.stringify(response.data));
                    getrules(cid)
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }

    const actschedule = (id) => {
        let a = window.confirm("Are you sure you want to continue?")
        if (a) {
            setShowloader(true)
            let data = JSON.stringify({
                "id": id
            });

            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: Url.baseurl + 'schedule/active',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    setShowloader(false)
                    console.log(JSON.stringify(response.data));
                    getlist()
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }
    const updateschedule = (schedule) => {
        setSch(sch => schedule)
        handleShow()
        setFullname(fullname => schedule?.name)
        setCid(cid => schedule?.id)
    }

    const addrule = (id) => {
        handleShow2()
        setCid(cid => id)
    }

    const schedulerule = (id) => {
        handleShow3()
        setCid(cid => id)
        getrules(id)
    }

    const getrules = (id) => {
        setShowloader(true)
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: Url.baseurl + 'schedule/rule/' + id,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log("getting rules list")
                console.log(JSON.stringify(response.data));
                setShowloader(false)
                setRules(rules => response.data.data)
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const validate = () => {
        if (
            fullname.length == 0
        ) {
            setFormerror("Please enter a valid user information");
            return false;
        } else {
            setFormerror("");
            return true;
        }
    };

    const validate2 = () => {
        if (
            template.length == 0 ||
            days.length == 0 ||
            parseInt(days) == 0 ||
            msg.length == 0
        ) {
            setFormerror2("Please enter a valid user information");
            return false;
        } else {
            setFormerror2("");
            return true;
        }
    };


    const upschedule = () => {
        //call API
        if (validate()) {
            setShowloader(true);
            let data = JSON.stringify({
                "id": cid,
                "name": fullname,
                "company_id": "virtako",
                "user_id": "u11"
            });

            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: Url.baseurl + 'schedule',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    setShowloader(false);
                    if (response.data.status == 201) {
                        handleClose()
                        getlist()
                    } else {
                        setFormerror("SCHEDULE CREATION FAILED");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    const createrule = () => {
        if (validate2()) {
            let data = JSON.stringify({
                "schedule_id": cid,
                "action_days": days,
                "template_id": template,
                "msg": msg,
                "channel": channel,
                "action_time": actiontime
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: Url.baseurl + 'schedule/rule',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));
                    //alert("Rule Created")
                    handleClose2()
                    getrules(cid)
                    handleShow3()
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }
    useEffect(() => {
        getlist();
    }, []);

    const getactivity = (id) => {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: Url.baseurl + 'schedule/communications/scheduled/' + id,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setActivity((activity) => response.data.data)
            })
            .catch((error) => {
                console.log(error);
            });

    }
    const showcomm = (id) => {
        getactivity(id)
        handleShow4()
    }
    return (
        <div className="mybody">
            <center>
                <Card className="form-center shadow" style={{ width: "60%" }}>
                    <Header />
                    <Row style={buttonrow}>
                        <Col>
                            <Link to="/createschedule">
                                <Button style={{marginLeft:20,marginTop:10}}>+ New Schedule</Button>
                            </Link>        
                        </Col>
                    </Row>
                    <Card.Body>
                        <Row>
                            <Col>
                                <h2>Schedules</h2>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Company</th>
                                            <th>Actions</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {schlist?.length > 0
                                            ? schlist.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {item.schedule.name}<br />
                                                            {item.schedule.active == 1 ? <span className="cgreen">Active</span> : <span className="cred">Inactive</span>}
                                                        </td>
                                                        <td>{item.schedule.company_id}</td>
                                                        <td>
                                                            <Button variant="secondary" onClick={updateschedule.bind(this, item.schedule)}>Update</Button>{' '}
                                                            <Button variant="success" onClick={addrule.bind(this, item.schedule.id)}>Add Rule</Button>{' '}
                                                            <Button variant="warning" onClick={schedulerule.bind(this, item.schedule.id)}>Rules</Button>{' '}
                                                            <Button variant="danger" onClick={delschedule.bind(this, item.schedule.id)}>Delete</Button>{' '}
                                                            <Button variant="dark" onClick={actschedule.bind(this, item.schedule.id)}>{item.schedule.active == 1 ? 'De activate' : 'Activate'}</Button>{' '}
                                                            <Button variant="info" onClick={showcomm.bind(this, item.schedule.id)}>Communications</Button>
                                                        </td>

                                                    </tr>
                                                );
                                            })
                                            : null}


                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                {showloader && <LoaderSpinner type="Circles" color="#00aced" />}
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Schedule</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <label>Name</label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter schedule name ..."
                                    value={fullname}
                                    onChange={(e) => setFullname(e.target.value)}
                                />
                            </Form.Group>



                            {formerror.length > 0 ? (
                                <Alert className="my-4" variant="danger">
                                    {formerror}
                                </Alert>
                            ) : null}
                            {passworderror.length > 0 ? (
                                <Alert className="my-4" variant="danger">
                                    {passworderror}
                                </Alert>
                            ) : null}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            type="button"
                            disabled={showloader}
                            onClick={upschedule}
                        >Update</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={show2}
                    onHide={handleClose2}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Create Rule</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            {formerror2.length > 0 ? (
                                <Alert className="my-4" variant="danger">
                                    {formerror2}
                                </Alert>
                            ) : null}
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Activity Days (use - sign for days before event)"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" placeholder="Activity Days (use - sign for days before event)" value={days} onChange={(e) => setDays(e.target.value)} />
                                </FloatingLabel>
                                {days.length > 0 && (parseInt(days) > 0 ? <Alert variant={'success'}>
                                    You are setting action on {days} day[s] after sowing date
                                </Alert> : <Alert variant={'info'}>
                                    You are setting action on {parseInt(days) * -1} day[s] before sowing date
                                </Alert>)}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Action Time"
                                    className="mb-3"
                                >
                                    <Form.Control type="time" placeholder="Action Time" value={actiontime} onChange={(e) => setActiontime(e.target.value)} />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Template Id"
                                    className="mb-3"
                                >
                                    <Form.Control type="text" placeholder="Template Id" value={template} onChange={(e) => setTemplate(e.target.value)} />
                                </FloatingLabel>
                            </Form.Group>

                            <FloatingLabel controlId="floatingTextarea2" label="Message">
                                <Form.Control
                                    as="textarea"
                                    placeholder="SMS Template"
                                    style={{ height: '100px' }}
                                    value={msg} onChange={(e) => setMsg(e.target.value)}
                                />
                            </FloatingLabel>

                            <FloatingLabel controlId="floatingSelect" label="Channel" style={{ marginTop: '4%' }}>
                                <Form.Select aria-label="Floating label select example">

                                    <option value={channel}>{channel}</option>

                                </Form.Select>
                            </FloatingLabel>



                            {formerror.length > 0 ? (
                                <Alert className="my-4" variant="danger">
                                    {formerror}
                                </Alert>
                            ) : null}

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose2}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            type="button"
                            disabled={showloader}
                            onClick={createrule}
                        >Create</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={show3}
                    onHide={handleClose3}
                    backdrop="static"
                    keyboard={false}
                    size="lg"

                >
                    <Modal.Header closeButton>
                        <Modal.Title>Rule List</Modal.Title>
                        {' '}&nbsp;&nbsp;<Button variant="success" onClick={addrule.bind(this, cid)}>Add Rule</Button>{' '}

                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col className="table">
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Action Day</th>
                                            <th>Template Id</th>
                                            <th>Massage</th>
                                            <th>Channel</th>
                                            <th>Sowing Date</th>



                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rules?.length > 0
                                            ? rules.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {item.action_days}<br /><br />
                                                            <strong className="txtsm">Action Time</strong>&nbsp;&nbsp;{item.action_time}<br />
                                                            <Button variant="danger" onClick={delrule.bind(this, item.id)}>Delete</Button>{' '}


                                                        </td>
                                                        <td>{item.template_id}</td>
                                                        <td>{item.msg}</td>
                                                        <td>{item.channel}</td>
                                                        <td>{item.action_date}</td>


                                                    </tr>
                                                );
                                            })
                                            : null}


                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose3}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
                <Modal
                    show={show4}
                    onHide={handleClose4}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Communications</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col className="table">
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Phone</th>
                                            <th>Status</th>
                                            <th>Massage</th>
                                            <th>Channel</th>
                                            <th>Trigger Date</th>



                                        </tr>
                                    </thead>
                                    <tbody>
                                        {activity?.length > 0
                                            ? activity.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {item.phone}


                                                        </td>
                                                        <td>{item.status}</td>
                                                        <td>{item.msg}</td>
                                                        <td>{item.channel}</td>
                                                        <td>{item.ev_date}</td>


                                                    </tr>
                                                );
                                            })
                                            : null}


                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose4}>
                            Close
                        </Button>

                    </Modal.Footer>
                </Modal>
            </center>
        </div>
    );
}
const buttonrow={
    flexDirection:'row',
    marginBottom:20
  }
export default Schedulelist;
