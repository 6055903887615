import React, { useState, useEffect } from 'react'
import {Link } from "react-router-dom";
import { Card, Button, Col, Row, Badge, Modal,Popover } from "react-bootstrap"
import Header from "./Header"
import { Url } from '../constant/Url'
import axios from "axios";
//import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import Dashcard from '../common/dashcard';

Chart.register(ArcElement, Tooltip, Legend);

function Dashboard() {
    let u = JSON.parse(localStorage.getItem('user'))

    const [userinfo, setUserinfo] = useState(u)
    const [leads, setLeads] = useState(0)
    const [redeem, setRedeem] = useState(0)
    const [medium, setMedium] = useState('UTM Medium')
    const [source, setSource] = useState('UTM Source')
    const [campaign, setCampaign] = useState('UTM Campaign')
    const [clickdata, setClickdata] = useState({})
    const [dashdata, setDashdata] = useState({})
    const [users, setUsers] = useState(0)
    const [agents, setAgents] = useState(0)
    const [myleads, setMyleads] = useState(0)
    const [sentmsg, setSentmsg] = useState(0)
    const [pendingmsg, setPendingmsg] = useState(0)
    const [leadslist, setLeadslist] = useState([]);
    const [show, setShow] = useState(false);

    const getmyleadslist = (agentid) => {
        var data = JSON.stringify({
            "agent_id": agentid
        });

        var config = {
            method: 'post',
            url: Url.baseurl + 'agentlead/list',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setLeadslist(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const handleClose = () => setShow(false);
    const handleShow = (item) => {
        setShow(true);
        //  setCurragent(item);
        getmyleadslist(item);
    }
    const getLeads = () => {

        var config = {
            method: 'get',
            url: Url.baseurl + 'users/dashboard',
            headers: {}
        };


        axios(config)
            .then(function (response) {

                console.log(JSON.stringify(response.data));
                setDashdata(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const getmyleads = () => {
        var data = JSON.stringify({
            "agent_id": u.userid
        });

        var config = {
            method: 'post',
            url: Url.baseurl + 'agentlead/count',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setMyleads(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    useEffect(() => {

        console.log('u----', u)
        console.log('role', u.role.toString())
        //  setUserinfo(u)

    }, [])
    useEffect(() => {
        getLeads()

        console.log('Userinfo', userinfo.role)
    }, [])
    useEffect(() => {
        setLeads(dashdata.leads)
        setRedeem(dashdata.redeem)
        setUsers(dashdata.users)
        setAgents(dashdata.agents)
        setSentmsg(dashdata.send_msg)
        setPendingmsg(dashdata.pending_msg)
        //  
    }, [dashdata])
    useEffect(() => {
        getmyleads()
    }, [agents])
    const data = {
        labels: ['Leads', 'Redeemed Leads'],
        datasets: [
            {
                label: '# of Leads',
                data: [leads - redeem, redeem],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(37,20,185, 0.2)'

                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(37,20,185, 1)'
                ],
                borderWidth: 1,
            },
        ],
    };
    const data2 = {
        labels: ['Users'],
        datasets: [
            {
                label: '# of Users',
                data: [users],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',

                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',

                ],
                borderWidth: 2,
            },
        ],
    };
    const data3 = {
        labels: ['Redeemed Leads'],
        datasets: [
            {
                label: '# of Leads',
                data: [redeem],
                backgroundColor: [
                    'rgba(37,20,185, 0.2)'

                ],
                borderColor: [
                    'rgba(37,20,185, 1)'
                ],
                borderWidth: 1,
            },
        ],
    };
    const data5 = {
        labels: ['Agents'],
        datasets: [
            {
                label: '# of agents',
                data: [agents],
                backgroundColor: [
                    'rgba(7,51,27, 0.2)'

                ],
                borderColor: [
                    'rgba(7,51,27, 1)'
                ],
                borderWidth: 1,
            },
        ],
    };
    const data6 = {
        labels: ['Redeemed Leads'],
        datasets: [
            {
                label: '# of Leads',
                data: [myleads],
                backgroundColor: [
                    'rgba(37,20,185, 0.2)'

                ],
                borderColor: [
                    'rgba(37,20,185, 1)'
                ],
                borderWidth: 1,
            },
        ],
    };
    const data7 = {
        labels: [`Sent Messeges ${sentmsg}`, `Scheduled Messeges ${pendingmsg}`],
        datasets: [
            {
                label: '# of Communications',
                data: [sentmsg, pendingmsg],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(37,20,185, 0.2)'

                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(37,20,185, 1)'
                ],
                borderWidth: 1,
            },
        ],
    };
    const popoverTop = (
        <Popover id="popover-trigger-click" title="Popover top" className="popoverStyle">
          Create users who can login to the cms.
        </Popover>)
    return (
        <div className="mybody">
            <center>
            <Card className="form-center shadow" style={{width:'90%'}}>
                <Header />
                <Card.Body>
                    <Row>
                        {u.role == "superadmin" || u.role == 'admin' ? <Col>
                            <Dashcard 
                                title="Users"
                                tooltipId={"usersTooltip"} 
                                tooltipMessage={"Create users who can login to the cms."}
                                path="/createuser" 
                            />
                        </Col> : null}
                        {u.role == 'superadmin' || u.role == 'admin' ? <Col>
                            <Dashcard 
                                title="Leads"
                                tooltipId={"leadsTooltip"} 
                                tooltipMessage={"View collected leads data from the app."}
                                path="/datalist" 
                            />
                        </Col> : null}
                        {u.role == 'superadmin' || u.role == 'admin' ? <Col>
                            <Dashcard 
                                title="Schedule"
                                tooltipId={"scheduleTooltip"} 
                                tooltipMessage={"Create and view schedules and add rules to them for bulk communication."}
                                path="/schedulelist" 
                            />
                       </Col> : null}
                       {u.role == 'superadmin' || u.role == 'admin' ? <Col>
                            <Dashcard 
                                title="Campaign"
                                tooltipId={"campaignTooltip"} 
                                tooltipMessage={"Create campaigns"}
                                path="/campaignlist" 
                            />
                       </Col> : null}
                        
                    </Row>


                    {u.role == 'superadmin' || u.role == 'admin' ? <Row> 
                        <Col md={3} sm={12} xs={12} lg={3} style={{ marginTop: 15 }}><Card style={{ width: '90%' }}>
                        <Card.Body>
                            <Card.Title>Total Leads</Card.Title>
                            <Pie data={data} />
                            <h4><Badge bg="success">{leads}</Badge>  </h4>
                            <Card.Text>
                                View collected data from landing page.
                            </Card.Text>
                            <Link to="/datalist" label="LOGOUT" className="mybtn" >View Detail</Link>
                        </Card.Body>
                    </Card></Col>

                        <Col md={3} sm={12} xs={12} lg={3} style={{ marginTop: 15 }}><Card style={{ width: '90%' }}>
                            <Card.Body>
                                <Card.Title>Total Communications</Card.Title>
                                <Pie data={data7} />
                                <h4><Badge bg="success">{sentmsg + pendingmsg}</Badge>  </h4>
                                <Card.Text>
                                    Scheduled and Sent Communications.
                                </Card.Text>

                            </Card.Body>
                        </Card></Col>
                        <Col md={3} sm={12} xs={12} lg={3} style={{ marginTop: 15 }}><Card style={{ width: '90%' }}>
                            <Card.Body>
                                <Card.Title>Users in platform</Card.Title>
                                <Pie data={data2} />
                                <h4><Badge bg="danger">{users}</Badge>  </h4>
                                <Card.Text>
                                    List of users who can login to the cms.
                                </Card.Text>
                                <Link to="/userlist" label="LOGOUT" className="mybtn">View Detail</Link>
                            </Card.Body>
                        </Card></Col>
                        <Col md={3} sm={12} xs={12} lg={3} style={{ marginTop: 15 }}><Card style={{ width: '90%' }}>
                            <Card.Body>
                                <Card.Title>User Code Verification</Card.Title>
                                <Pie data={data3} />
                                <h4><Badge bg="success">{redeem}</Badge>  </h4>
                                <Card.Text>
                                    Accept user verification code.
                                </Card.Text>
                                <Link to="/verifycode" label="LOGOUT" className="mybtn" >Verify user Code</Link>
                            </Card.Body>
                        </Card></Col>
                        {u.role == 'superadmin' || u.role == 'admin' ? <Col md={3} sm={12} xs={12} lg={3} style={{ marginTop: 15 }}><Card style={{ width: '90%' }}>
                            <Card.Body>
                                <Card.Title>Agents in platform</Card.Title>
                                <Pie data={data5} />
                                <h4><Badge bg="danger">{agents}</Badge>  </h4>
                                <Card.Text>
                                    List of agents who can login to the cms.
                                </Card.Text>
                                <Link to="/agentlist" label="LOGOUT" className="mybtn">View Detail</Link>
                            </Card.Body>
                        </Card></Col> : null}
                    </Row> : null}


                    {u.role == 'superadmin' || u.role == 'admin' || u.role == 'agent' ? <Row>

                        {u.role == 'agent' ? <Col md={3} sm={12} xs={12} lg={3} style={{ marginTop: 15 }}><Card style={{ width: '90%' }}>
                            <Card.Body>
                                <Card.Title>User Code Verification</Card.Title>
                                <Pie data={data3} />
                                <h4><Badge bg="success">{redeem}</Badge>  </h4>
                                <Card.Text>
                                    Accept user verification code.
                                </Card.Text>
                                <Link to="/verifycode" label="LOGOUT" className="mybtn" >Verify user Code</Link>
                            </Card.Body>
                        </Card></Col> : null}
                        {u.role == 'agent' ? <Col md={3} sm={12} xs={12} lg={3} style={{ marginTop: 15 }}><Card style={{ width: '90%' }}>
                            <Card.Body>
                                <Card.Title>My Leads</Card.Title>
                                <Pie data={data6} />
                                <h4><Badge bg="danger">{myleads}</Badge>  </h4>
                                <Card.Text>
                                    List of agents who can login to the cms.
                                </Card.Text>
                                <Button className="btnstyle" onClick={handleShow.bind(this, u?.userid)} variant="success">View leads</Button>{' '}
                            </Card.Body>
                        </Card></Col> : null}
                    </Row> : null}





                </Card.Body>
            </Card>
            </center>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton variant="danger" >
                    <Modal.Title>{u?.username + ' (' + myleads + ' leads)'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="listholder">

                        {leadslist?.length > 0 ?
                            leadslist.map((item, index) => {
                                let p = item.phone.toString();
                                let f = p.substring(0, 2);
                                let e = p.slice(-3);
                                let ph = f + "XXXXX" + e;
                                let mail = item.email.toString();
                                let first = mail.substring(0, 2);
                                let last = mail.slice(-2);
                                let mail2 = first + "XXXXX" + last;
                                return <Card style={{ width: '99%', marginBottom: 10 }}>

                                    <Card.Body>
                                        <Card.Title>{item.first_name + " " + item.last_name}</Card.Title>
                                        <Card.Text>
                                            <p> <strong>Email </strong> &nbsp; {mail2}</p>
                                            <p> <strong>Phone </strong> &nbsp; {ph}</p>
                                            <p> <strong>Gender </strong> &nbsp; {item.gender}</p>
                                            <p> <strong>City </strong> &nbsp; {item.city}</p>
                                            <p> <strong>Pincode </strong> &nbsp; {item.pincode}</p>
                                        </Card.Text>

                                    </Card.Body>
                                </Card>
                            })
                            : null}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Dashboard

const infoIcon={
    position:'absolute',
    top:15,
    right:15,
    width:20,
    height:20,
    tintColor:'green'
  }