import React, { useState, useEffect } from "react";
import {
    Card,
    Button,
    Col,
    Row,
    Container,
    Form,
    Table,
    Dropdown,
    Alert,
    Modal,
    ProgressBar,
    Badge
} from "react-bootstrap";
//import Pagination from 'react-bootstrap/Pagination'
import Pagination from 'react-responsive-pagination';
import axios from "axios";
import Header from "./Header";
import LoaderSpinner from "../common/LoaderSpinner";
import { Url } from '../constant/Url'
import $ from 'jquery';
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import leaddata from '../Leads_Sample.csv';
import close from "../img/close.png";
import folder from "../img/folder.png"
import upload from "../img/upload.png";

function Datalist() {
    const [userlist, setUserlist] = useState([]);
    const [userexplist, setUserexplist] = useState([]);
    const [showloader, setShowloader] = useState(false);
    const [pages, setPages] = useState(0)
    const [dpages, setDpages] = useState(0)
    const [pi, setPi] = useState([])
    const [active, setActive] = useState(1)
    const [off, setOff] = useState(0)
    const [xx, setxx] = useState([]);
    const [filter, setFilter] = useState({})
    const [medium, setMedium] = useState('UTM Medium')
    const [source, setSource] = useState('UTM Source')
    const [campaign, setCampaign] = useState('UTM Campaign')
    const [startdate, setStartdate] = useState(new Date());
    const [fdate, setFdate] = useState('nil')
    const [edata, setEdata] = useState([])
    const [show, setShow] = useState(false);
    const [showexp, setShowexp] = useState(false);
    const [showexpbtn, setShowexpbtn] = useState(false)
    const [pr, setPr] = useState(9)
    const [modalcsv,setModalcsv]=useState(false)
    const [loading2,setLoading2]=useState(false)
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [alertduplicate, setAlertduplicate] = useState(false)
    const [dup, setDup] = useState('')

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let alldata = [];

    const [activity, setActivity] = useState([])

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [fromdate, setFromdate] = useState("")
    const [todate, setTodate] = useState("")

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
      };
    
      const handleUpload = () => {
        setAlertduplicate(false)
        if (file != null) {
          setLoading2(true)
          const formData = new FormData();
          formData.append('myfile', file);
          console.log("File is sent", file)
    
          var config = {
            method: "post",
            url: URL.baseurldt3 + "uploadagent",
            data: formData,
          };
    
          axios(config)
            .then(function (response) {
              console.log("Response in file upload", response.data);
              if (response.data.status = 201) {
                if (response.data.created == 0) {
                  setLoading2(false)
                  setAlertduplicate(true)
                  var d = "\nNo rows have been uploaded due to duplicate data"
                  setDup(d)
                }
                if (response.data.created != 0) {
                  setLoading2(false)
                  var duplicate = response.data.datasize - response.data.created
                  setAlertduplicate(true)
                  var d = "\nRows uploaded : " + response.data.created + " | Duplicate rows(not uploaded) : " + duplicate
                  setDup(d)
                  getlist(1);
                  setModalcsv(!modalcsv)
                  setFile(null)
                  setFileName('')
                }
    
              }
    
    
            })
            .catch(error => {
              console.log(error);
              setLoading2(false)
            });
        }
      };
    const exppush = (data) => {
        console.log('exppush called..')
        let expdata = []
        if (data.length > 0) {
            data.map((item, index) => {
                var tmp = {
                    id: index + 1,
                    name: item.name,
                    phone: item.phone,
                    website: item.website,
                    campaign_id: item.campaign_id,
                    source: item.source,
                    day: item.day,
                    month: item.month,
                    year: item.year,
                    fulldate: item.fulldate,
                    language: item.language
                }
                expdata.push(tmp)
            })
            ///////////append edata////////////////
            console.log('exppush setting edata..')
            setEdata(...edata, expdata)
        }
    }
    const goexp = async () => {
        let arr = []
        console.log('goexp called..')
        for (let i = 1; i < 3; i++) {
            console.log('getting data for item ' + i.toString())
            arr.push(getexplist(i));
        }

        await Promise.all(arr);

    }
    const resetfilter = () => {
        setMedium('UTM Medium')
        setCampaign('UTM Campaign')
        setSource('UTM Source')
    }

    const convertDate = (dd) => {
        let d = new Date(dd)
        let month = String(d.getMonth() + 1);
        let day = String(d.getDate());
        const year = String(d.getFullYear());

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return `${day}-${month}-${year}`;
    }

    const datefilter = async () => {
        setShowexpbtn(false)
        setShowexp(true)
        let cd = startdate
        let nd = convertDate(cd)
        setFdate(nd)
        // searchbydate(nd)

        let r = await getdumps();
        setShowexp(false)
        setShowexpbtn(true)
        console.log('new all data -------->')
        console.log(alldata)

    }
    const searchbydate = (cdate) => {
        alert(cdate)
    }
    const getpages = () => {

        var config = {
            method: 'get',
            url: Url.baseurl + 'leads/pages',
            headers: {}
        };


        axios(config)
            .then(function (response) {
                setShowloader(false)
                console.log(JSON.stringify(response.data));
                setPages(parseInt(response.data.data.pages));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const getpagesbydate = () => {
        let cd = startdate
        let nd = convertDate(cd)
        setFdate(nd)
        //  alert(nd)
        var config = {
            method: 'get',
            url: Url.Apiurl + 'getpagesbydate/' + medium + '-' + campaign + '-' + source + '-' + nd,
            headers: {}
        };


        axios(config)
            .then(function (response) {
                setShowloader(false)
                console.log(JSON.stringify(response.data));
                setDpages(parseInt(response.data.pages));
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const loadpg = (n) => {
        getlist(n);
        setActive(n);
        $('html, body').animate({
            scrollTop: $("#mybody").offset().top
        }, 1000);

    }
    const createpages = () => {
        let items = [];
        for (let number = 1; number <= pages; number++) {
            items.push(
                <Pagination.Item key={number} onClick={loadpg.bind(this, number)} active={number === active}>
                    {number}
                </Pagination.Item>,
            );
        }
        setPi(items)
    }
    const getIds = () => {
        let items = [];
        for (let number = off; number <= 50; number++) {
            items.push(number)
        }
        setxx(items)
    }
    const exportnow = async () => {


        handleShow()

    }
    const getdumps = async () => {
        setShowloader(true)
        let cd = startdate
        let nd = convertDate(cd)
        setFdate(nd)
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        console.log('fetch dump called.. ---------->')
        //  const ids = [1, 2, 3]; // Array of ids
        const ids = []
        for (let i = 1; i <= dpages + 1; i++) {
            ids.push(i)
        }
        const responses = await Promise.allSettled(
            ids.map(async id => {
                let offset = Math.abs(50 * (id - 1))
                let prs = parseInt((id / pages) * 100)
                setPr(prs)
                const res = await fetch(
                    "https://uy3mldqugd.execute-api.ap-south-1.amazonaws.com/Prod/getuserdatabydate/" + offset.toString() + '-' + medium + '-' + campaign + '-' + source + '-' + nd, requestOptions
                ).then(response => response.json())
                    .then(result => {
                        console.log('dumping ' + id.toString())
                        var tmpdata = result ? result.response : [];
                        console.log(tmpdata)
                        const odata = alldata
                        const newdata = odata.concat(tmpdata)
                        console.log('new data ---------->')
                        console.log(newdata)
                        alldata = newdata
                        setEdata(newdata)


                    })
                    .catch(error => console.log('error', error)); // Send request for each id
            })
        );
        setShowloader(false)
        console.log('data dump ------------>')
        console.log(responses)
        return responses
    }
    const getfetchlist = (n) => {
        let offset = Math.abs(50 * (n - 1))

        setShowloader(true)
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        console.log('fetch called.. ---------->')
        fetch("https://uy3mldqugd.execute-api.ap-south-1.amazonaws.com/Prod/getuserdata/" + offset.toString() + '-' + medium + '-' + campaign + '-' + source, requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result)
                setShowloader(false)
            })
            .catch(error => console.log('error', error));
    }
    const getlist = (n) => {
        let offset = Math.abs(100 * (n - 1))
        setOff(offset + 1)
        setShowloader(true)

        var data = JSON.stringify({
            "offset": offset
        });
        var config = {
            method: 'post',
            url: Url.baseurl + 'leads/list',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(config)
            .then(function (response) {
                setShowloader(false)
                //  console.log(JSON.stringify(response.data));
                setUserlist(response.data.data);
                getpages()
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const getexplist = async (n) => {
        let offset = Math.abs(50 * (n - 1))
        //setOff(offset + 1)
        setShowloader(true)
        console.log('getexplist called..')

        var config = {
            method: 'get',
            url: Url.Apiurl + 'getuserdata/' + offset.toString() + '-' + medium + '-' + campaign + '-' + source,
            headers: {}
        };


        axios(config)
            .then(function (response) {
                setShowloader(false)
                console.log(JSON.stringify('data ' + offset.toString(), response.data.response));
                setUserexplist(response.data.response);
                console.log('goexp pushing data..')
                //  exppush(response.data.response)
                setEdata(...edata, response.data.response)
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const exportcsv = () => {
        console.log('final exp------>')
        setShowloader(true);
        console.log(edata)
        var csvdata = [];
        var headingdata = ["id", "phone", "name", "crop", "plantation date", "district", "village", "acerages", "product preference", "acerages used", "redeem"]
        csvdata.push(headingdata)
        if (userlist?.length > 0) {
            userlist.map((item, index) => {
                var tmp = [index + 1, item.phone, item.first_name + " " + item.last_name, item.crop, item.plantation_date, item.district, item.village, item.acerages, item.product_preference, item.acerage_use, item.redeem]
                csvdata.push(tmp)
            })
        }
        console.log("csv", csvdata)
        let cd = startdate
        let nd = convertDate(cd)
        let csvContent = "data:text/csv;charset=unicode utf-8,"
            + csvdata.map(e => e.join(",")).join("\n");

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "my_data_" + nd + ".csv");
        document.body.appendChild(link);
        link.click();
        setShowloader(false);
    }

    const uploadcsv = () =>{
        console.log("hello")
        setModalcsv(!modalcsv);
    }
    const handleClose4 = () => setModalcsv(false);

    const openModal4 = () => {
        setModalcsv(!modalcsv);
    
      };

    const getFilters = () => {

        var config = {
            method: 'get',
            url: 'https://uy3mldqugd.execute-api.ap-south-1.amazonaws.com/Prod/getfilterdataraw',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setFilter(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    useEffect(() => {
        getpages();
        //  getpagesbydate()

    }, []);
    useEffect(() => {
        getlist(1);
        // alert('pages' + pages)
    }, [pages]);
    useEffect(() => {


        //getFilters()
        //   getfetchlist(2)

    }, [userlist]);
    useEffect(() => {


    }, [off]);

    useEffect(() => {

        // getlist(off);
    }, [medium]);
    useEffect(() => {

        //getlist(off);
    }, [campaign]);
    useEffect(() => {

        //getlist(off);
    }, [source]);
    useEffect(() => {
        //getpagesbydate()

    }, [startdate])

    const getactivity = (phone) => {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: Url.baseurl + 'schedule/communications/' + phone,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setActivity((activity) => response.data.data)
            })
            .catch((error) => {
                console.log(error);
            });

    }
    const showcomm = (phone) => {
        getactivity(phone)
        handleShow2()
    }
    const searchdate = () => {

    }
    return (
        <div className="mybody" id="mybody">
            <Modal className="useraddModal" scrollable show={modalcsv} onHide={handleClose4}>
                <Modal.Header style={{ borderBottomWidth: 0 }}>
                <span style={modalTitle}>Upload file</span>
                <a style={downloadBtn}
                    href={leaddata} target="_blank">
                    Download sample CSV
                </a>
                <br />
                <a onClick={openModal4}>
                    <img src={close} />{" "}
                </a>
                </Modal.Header>

                <Modal.Body style={{ borderBottomWidth: 0, }}>
                <p><b>Note:</b> While filling up the CSV file ensure that you enter the names of your fields correctly and that all phone numbers are valid<br/>
                If you do not have the data for certain fields keep them as NIL  (do not keep them blank)</p>
                {loading2 ? (
                    <div className="centeralign">
                    <p>Uploading CSV file. Please stay on this page...</p>
                    </div>
                ) : fileName != '' ?
                    <div>
                    <center style={{ marginTop: 30 }}>
                        Do you want to upload this file ?
                        <p style={{ fontSize: 16, marginTop: 20 }}><b>{fileName}</b></p></center>
                    </div>
                    : <center style={{ marginTop: 30 }}>
                    <img src={folder} style={{ width: 40, height: 40 }} /><br /><br />
                    <input type="file" style={{ marginLeft: '30%' }} onChange={handleFileChange} />
                    </center>
                }
                {alertduplicate == true && <center><p style={{ color: 'red' }}>{dup}</p></center>}
                </Modal.Body>

                <Modal.Footer>

                <Button className="uploadbtn" onClick={() => {
                    openModal4()
                    setFile(null)
                    setFileName('')
                }}>
                    Cancel
                </Button>
                <Button className="exportbtn" onClick={() => {
                    setFileName('')
                    setAlertduplicate(false)
                }
                }>
                    Reset
                </Button>
                <Button
                    variant="info"
                    style={addButton}
                    onClick={handleUpload}
                >
                    <img src={upload} style={addIcon} />
                    &nbsp;
                    <span style={{ color: "white"}}>
                    Upload
                    </span>
                </Button>

                </Modal.Footer>
            </Modal>
            <center>
                <Card className="form-center shadow" style={{ width: "90%" }}>
                    <Header />
                    <Card.Body>
                        <Row>
                            <Col style={searchBox}>
                                <Form.Control
                                    defaultValue={fromdate}

                                    onChange={e => { setFromdate(e.target.value) }}
                                    type="date"
                                    placeholder="From Date"
                                    title="Date from"
                                    style={inputText}
                                    max={todate == "" ? new Date().toISOString().split("T")[0] : todate.split("T")[0]}
                                />
                            </Col>
                            <Col style={searchBox}>
                                <Form.Control
                                    defaultValue={todate}

                                    onChange={e => { setTodate(e.target.value) }}
                                    type="date"
                                    placeholder="Date to"
                                    title="To date"
                                    style={inputText}
                                    min={fromdate?.split("T")[0]}
                                    max={new Date().toISOString().split("T")[0]}
                                />
                            </Col>
                            <Col md={2} lg={2} xs={6} sm={6}  style={searchBox}>
                                {/*<Button variant="dark" onClick={resetfilter}>Reset Filter</Button>{' '}*/}
                                <Button variant="dark" style={{width:'100%'}} onClick={searchdate}>Search By Date</Button>{' '}
                            </Col>
                            <Col md={2} lg={2} xs={6} sm={6} style={searchBox}>
                                <Button className="uploadbtn" onClick={uploadcsv}>Upload CSV</Button>{' '}
                            </Col>
                            <Col md={2} lg={2} xs={6} sm={6} style={searchBox}>
                                <Button className="exportbtn" onClick={exportcsv}>Export CSV</Button>{' '}
                            </Col>

                        </Row>
                        <Row>
                            <Col>


                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>#</th>

                                            <th>Phone</th>
                                            <th>Name</th>
                                            <th>Crop</th>
                                            <th>Plantation Date</th>
                                            <th>District</th>
                                            <th>Village</th>
                                            <th>Acerages</th>
                                            <th>Product Preference</th>
                                            <th>Acerages Used</th>

                                            <th>Redeem</th>
                                            <th>Entry Date</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            userlist?.length > 0
                                                ? userlist?.map((item, index) => {

                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{item.phone}<br /><Button variant="info" onClick={showcomm.bind(this, item.phone)}>Communications</Button></td>
                                                            <td>{item.first_name}</td>
                                                            <td>{item.crop}</td>
                                                            <td>{item.plantation_date}</td>
                                                            <td>{item.district}</td>
                                                            <td>{item.village}</td>
                                                            <td>{item.acerages}</td>
                                                            <td>{item.product_preference}</td>
                                                            <td>{item.acerage_use}</td>
                                                            <td>{item.redeem == 1 ? <Badge bg="success">Redeemed</Badge> : <Badge bg="danger">Not redeemed</Badge>}</td>
                                                            <td>{moment(parseInt(item.time_stamp)).format("DD-MM-YYYY h:mm:ss")}</td>
                                                        </tr>
                                                    );

                                                })
                                                : <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>

                                                    <td>
                                                        <Alert variant={'danger'}>
                                                            No leads found!

                                                        </Alert>
                                                    </td></tr>}
                                    </tbody>
                                </Table>

                            </Col>
                        </Row>
                        <Row><Col>
                            {/*  <Pagination size="sm">{pi}</Pagination>*/}
                            <Pagination
                                current={active}
                                total={pages + 1}
                                onPageChange={(n) => { loadpg(n) }}
                            />
                        </Col></Row>
                    </Card.Body>
                </Card>
                {showloader && <LoaderSpinner type="Circles" color="#00aced" />}
            </center>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Export Leads</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <label> Select Date</label>
                            <br />
                            <DatePicker selected={startdate} dateFormat="yyyy-MM-dd" onChange={(date) => setStartdate(date)} />
                            <br /> <br />
                            <Button variant="dark" onClick={datefilter}>Download</Button>{' '}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {showexp ? <>
                                <br /> <Alert variant="primary">
                                    Getting Leads, Please wait
                                </Alert><br />
                                <ProgressBar animated now={pr} />
                            </> : null}

                        </Col>
                    </Row>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {showexpbtn ? <Button variant="primary" onClick={exportcsv}>
                        Download CSV
                    </Button> : null}
                </Modal.Footer>
            </Modal>
            <Modal
                show={show2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Communications</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className="table">
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Phone</th>
                                        <th>Status</th>
                                        <th>Massage</th>
                                        <th>Channel</th>
                                        <th>Trigger Date</th>



                                    </tr>
                                </thead>
                                <tbody>
                                    {activity?.length > 0
                                        ? activity.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {item.phone}


                                                    </td>
                                                    <td>{item.status}</td>
                                                    <td>{item.msg}</td>
                                                    <td>{item.channel}</td>
                                                    <td>{item.ev_date}</td>


                                                </tr>
                                            );
                                        })
                                        : null}


                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    );
}
const inputText = {
    height: 40,
    paddingTop: 3,
    fontSize: 14,
};
const searchBox = {
    borderRadius: 4,
    height: 40,
    marginLeft: '-1%',
    marginTop: 10,

}
const modalTitle = {
    fontSize: 22,
    fontWeight: "600",
  };
  const downloadBtn = {
    color: "orange",
    fontSize: 13,
    marginRight: 0,
    borderWidth: 0,
    marginLeft: "-15%",
    marginTop: 5,
  };
  const addButton = {
    backgroundColor: "orange",
    borderWidth: 1,
    borderColor: "orange",
  };
  const addIcon = {
    width: 12,
    height: 12,
  };
export default Datalist;
