import React, { useState, useContext } from 'react'
import { Container, Row, Col, ListGroup, Button,Card } from 'react-bootstrap'
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";


const LoaderSpinner =({type,timeout,color})=>{
    return (
    <div className="loader">
        <Loader
        type= {type}
        color= {color}
        height={100}
        width={100}
        timeout={timeout} //3 secs
      />


    </div>


    )
}
export default LoaderSpinner;