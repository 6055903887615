import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Notfound from './components/Notfound'
import Home from './components/Home'
import Admin from './components/Admin'
import Dashboard from './components/Dashboard'
import Createuser from './components/Createuser'
import Datalist from './components/Datalist'
import Userlist from './components/Userlist'
import Agentlist from './components/Agentlist'
import Verifycode from './components/Verifycode'
import Createschedule from './components/Createschedule';
import Schedulelist from './components/Schedulelist';
import Createcampaign from './components/Createcampaign';
import Campaignlist from './components/Campaignlist';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Admin />} />
        <Route exact path="home" element={<Admin />} />
        <Route exact path="admin" element={<Admin />} />
        <Route exact path="dashboard" element={<Dashboard />} />
        <Route exact path="createuser" element={<Createuser />} />
        <Route exact path="userlist" element={<Userlist />} />
        <Route exact path="datalist" element={<Datalist />} />
        <Route exact path="agentlist" element={<Agentlist />} />
        <Route exact path=":langs" element={<Home />} />
        <Route exact path="verifycode" element={<Verifycode />} />
        <Route exact path="createschedule" element={<Createschedule />} />
        <Route exact path="schedulelist" element={<Schedulelist />} />
        <Route exact path="createcampaign" element={<Createcampaign/>} />
        <Route exact path="campaignlist" element={<Campaignlist/>} />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <Notfound />
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

